var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100",
      attrs: { id: "esg" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_4_5")))])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("h4", { staticClass: "section1_item1_title font-weight-bold" }, [
              _vm._v(" " + _vm._s(_vm.$t("section_1.item_1.title")) + " ")
            ])
          ]),
          _c("div", { staticClass: "col-lg-12" }, [
            _c("p", {
              class:
                "section1_item1_content p_font_size " +
                (this.$i18n.locale == "en-us" ? "justify-content-start" : ""),
              domProps: {
                innerHTML: _vm._s(_vm.$t("section_1.item_1.content"))
              }
            }),
            _c("p", {
              staticClass: "section1_item1_content2 p_font_size",
              domProps: {
                innerHTML: _vm._s(_vm.$t("section_1.item_1.content2"))
              }
            })
          ])
        ])
      ]),
      _c("section", { staticClass: "grph-area ptb-100 my-5" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c(
                "div",
                { staticClass: "grph col-lg-7 justify-content-center" },
                [
                  _c("div", { staticClass: "promise " }, [
                    _c("h4", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_1.item_2.title")) + " "
                      )
                    ]),
                    _c("div", {
                      class:
                        "p_font_size " +
                        (this.$i18n.locale != "en-us" ? "text-center" : ""),
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_1.item_2.content"))
                      }
                    })
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _c("section", { staticClass: "ptb-100 my-5" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            {
              staticClass:
                "row flex-row-reverse align-items-center text_space mb-5"
            },
            [
              _c("div", { staticClass: "col-lg-6 px-5" }, [
                _c(
                  "div",
                  {
                    class:
                      "approach-content text_space " +
                      (this.$i18n.locale == "en-us" ? "text-left" : "")
                  },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("section_2.item_4.title")))
                    ]),
                    _c("p", {
                      staticClass: "mt-3 p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_4.content"))
                      }
                    }),
                    _c("p", {
                      staticClass: "mt-3 p_font_size section_2_content2",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_4.content2"))
                      }
                    })
                  ]
                )
              ]),
              _vm._m(0)
            ]
          ),
          _c("div", { staticClass: "row  align-items-center text_space" }, [
            _c("div", { staticClass: "col-lg-6 px-5" }, [
              _c(
                "div",
                {
                  class:
                    "approach-content text_space " +
                    (this.$i18n.locale == "en-us" ? "text-left" : "")
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("section_2.item_1.title")))]),
                  _c("p", {
                    staticClass: "mt-3 p_font_size",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_2.item_1.content"))
                    }
                  }),
                  _c("p", {
                    staticClass: "mt-3 p_font_size section_2_content2",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_2.item_1.content2"))
                    }
                  }),
                  _c("p", {
                    staticClass: "mt-3 p_font_size section_2_content2",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_2.item_1.content3"))
                    }
                  })
                ]
              )
            ]),
            _vm._m(1)
          ]),
          _c(
            "div",
            {
              staticClass:
                "row flex-row-reverse align-items-center align-items-center text_space"
            },
            [
              _c("div", { staticClass: "col-lg-6 px-5" }, [
                _c(
                  "div",
                  {
                    class:
                      "approach-content text_space " +
                      (this.$i18n.locale == "en-us" ? "text-left" : "")
                  },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("section_2.item_2.title")))
                    ]),
                    _c("p", {
                      staticClass: "mt-3 p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_2.content"))
                      }
                    }),
                    _c("p", {
                      staticClass: "mt-3 p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_2.content2"))
                      }
                    })
                  ]
                )
              ]),
              _vm._m(2)
            ]
          ),
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col-lg-6 px-5" }, [
              _c(
                "div",
                {
                  class:
                    "approach-content text_space " +
                    (this.$i18n.locale == "en-us" ? "text-left" : "")
                },
                [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("section_2.item_3.title")))]),
                  _c("p", {
                    staticClass: "mt-3 p_font_size",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_2.item_3.content"))
                    }
                  }),
                  _c("p", {
                    staticClass: "mt-3 p_font_size",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_2.item_3.content2"))
                    }
                  })
                ]
              )
            ]),
            _vm._m(3)
          ])
        ])
      ]),
      _c("section", { staticClass: "my-5" }, [
        _c("div", { staticClass: "section-title pb-0" }, [
          _c("h3", [_vm._v(_vm._s(_vm.$t("section_3.title")))])
        ]),
        _c(
          "div",
          { staticClass: "container", staticStyle: { "max-width": "1000px" } },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-4 col-md-6 pb-5" }, [
                _vm._m(4),
                _c("div", { staticClass: "pt-3" }, [
                  _c("h4", { staticClass: "EGS_title text-center pb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_3.item_1.title")) + " ")
                  ]),
                  _c("div", { staticClass: "d-flex " }, [
                    _c("ul", {
                      staticClass: "EGS_content_ul p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_3.item_1.content"))
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-4 col-md-6 pb-5" }, [
                _vm._m(5),
                _c("div", { staticClass: "pt-3" }, [
                  _c("h4", { staticClass: "EGS_title text-center pb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_3.item_2.title")) + " ")
                  ]),
                  _c("div", { staticClass: "d-flex " }, [
                    _c("ul", {
                      staticClass: "EGS_content_ul p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_3.item_2.content"))
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-4 col-md-6 pb-5" }, [
                _vm._m(6),
                _c("div", { staticClass: "pt-3" }, [
                  _c("h4", { staticClass: "EGS_title text-center pb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_3.item_3.title")) + " ")
                  ]),
                  _c("div", { staticClass: "d-flex " }, [
                    _c("ul", {
                      staticClass: "EGS_content_ul p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_3.item_3.content"))
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-4 col-md-6 pb-5" }, [
                _vm._m(7),
                _c("div", { staticClass: "pt-3" }, [
                  _c("h4", { staticClass: "EGS_title text-center pb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_3.item_4.title")) + " ")
                  ]),
                  _c("div", { staticClass: "d-flex " }, [
                    _c("ul", {
                      staticClass: "EGS_content_ul p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_3.item_4.content"))
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-4 col-md-6 pb-5" }, [
                _vm._m(8),
                _c("div", { staticClass: "pt-3" }, [
                  _c("h4", { staticClass: "EGS_title text-center pb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_3.item_5.title")) + " ")
                  ]),
                  _c("div", { staticClass: "d-flex " }, [
                    _c("ul", {
                      staticClass: "EGS_content_ul p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_3.item_5.content"))
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-4 col-md-6 pb-5" }, [
                _vm._m(9),
                _c("div", { staticClass: "pt-3" }, [
                  _c("h4", { staticClass: "EGS_title text-center pb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_3.item_6.title")) + " ")
                  ]),
                  _c("div", { staticClass: "d-flex " }, [
                    _c("ul", {
                      staticClass: "EGS_content_ul p_font_size",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_3.item_6.content"))
                      }
                    })
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6 p-0" }, [
      _c("div", { staticClass: "approach-img" }, [
        _c("img", {
          attrs: {
            src: require("assets/project_images/pic/ESG_02_4.jpg"),
            alt: "Image"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6 p-0" }, [
      _c("div", { staticClass: "approach-img" }, [
        _c("img", {
          attrs: {
            src: require("assets/project_images/pic/ESG_02_2.jpg"),
            alt: "Image"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6 p-0" }, [
      _c("div", { staticClass: "approach-img" }, [
        _c("img", {
          attrs: {
            src: require("assets/project_images/pic/esg_02.jpg"),
            alt: "Image"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6 p-0" }, [
      _c("div", { staticClass: "approach-img" }, [
        _c("img", {
          attrs: {
            src: require("assets/project_images/pic/ESG_03.png"),
            alt: "Image"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "approach-img EGS_icon mx-auto rounded-circle" },
      [
        _c("img", {
          staticClass: "p-5",
          attrs: { src: require("assets/project_images/pic/EGS_icon_01.svg") }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "approach-img EGS_icon mx-auto rounded-circle" },
      [
        _c("img", {
          staticClass: "p-5",
          attrs: {
            src: require("assets/project_images/pic/EGS_icon_02.svg"),
            width: "161",
            height: "161"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "approach-img EGS_icon mx-auto rounded-circle" },
      [
        _c("img", {
          staticClass: "p-5",
          attrs: {
            src: require("assets/project_images/pic/EGS_icon_03.svg"),
            width: "161",
            height: "161"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "approach-img EGS_icon mx-auto rounded-circle" },
      [
        _c("img", {
          staticClass: "p-5",
          attrs: {
            src: require("assets/project_images/pic/EGS_icon_04.svg"),
            width: "161",
            height: "161"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "approach-img EGS_icon mx-auto rounded-circle" },
      [
        _c("img", {
          staticClass: "p-5",
          attrs: {
            src: require("assets/project_images/pic/EGS_icon_05.svg"),
            width: "161",
            height: "161"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "approach-img EGS_icon mx-auto rounded-circle" },
      [
        _c("img", {
          staticClass: "p-5",
          attrs: {
            src: require("assets/project_images/pic/EGS_icon_06.svg"),
            width: "161",
            height: "161"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }