<template>
  <div id="esg" class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_4_5") }}</h2>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h4 class="section1_item1_title font-weight-bold">
            {{ $t("section_1.item_1.title") }}
          </h4>
        </div>
        <div class="col-lg-12">
          <p :class="`section1_item1_content p_font_size ${(this.$i18n.locale == 'en-us' ? 'justify-content-start' : '')}`"
            v-html="$t('section_1.item_1.content')"></p>
          <p class="section1_item1_content2 p_font_size" v-html="$t('section_1.item_1.content2')"></p>
        </div>
      </div>
    </div>
    <section class="grph-area ptb-100 my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="grph col-lg-7 justify-content-center">
              <div class="promise ">
                <h4>
                  {{ $t("section_1.item_2.title") }}
                </h4>
                <div :class="`p_font_size ${(this.$i18n.locale != 'en-us' ? 'text-center' : '')}`"
                  v-html="$t('section_1.item_2.content')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ptb-100 my-5">
      <div class="container">
        <div class="row flex-row-reverse align-items-center text_space mb-5">
          <div class="col-lg-6 px-5">
            <div :class="`approach-content text_space ${(this.$i18n.locale == 'en-us' ? 'text-left' : '')}`">
              <h3>{{ $t("section_2.item_4.title") }}</h3>
              <p class="mt-3 p_font_size" v-html="$t('section_2.item_4.content')"></p>
              <p class="mt-3 p_font_size section_2_content2" v-html="$t('section_2.item_4.content2')"></p>
            </div>
          </div>

          <div class="col-lg-6 p-0">
            <div class="approach-img">
              <img src="~assets/project_images/pic/ESG_02_4.jpg" alt="Image" />
            </div>
          </div>
        </div>

        <div class="row  align-items-center text_space">
          <div class="col-lg-6 px-5">
            <div :class="`approach-content text_space ${(this.$i18n.locale == 'en-us' ? 'text-left' : '')}`">
              <h3>{{ $t("section_2.item_1.title") }}</h3>
              <p class="mt-3 p_font_size" v-html="$t('section_2.item_1.content')"></p>
              <p class="mt-3 p_font_size section_2_content2" v-html="$t('section_2.item_1.content2')"></p>
              <p class="mt-3 p_font_size section_2_content2" v-html="$t('section_2.item_1.content3')"></p>
            </div>
          </div>

          <div class="col-lg-6 p-0">
            <div class="approach-img">
              <img src="~assets/project_images/pic/ESG_02_2.jpg" alt="Image" />
            </div>
          </div>
        </div>

        <div class="row flex-row-reverse align-items-center align-items-center text_space">
          <div class="col-lg-6 px-5">
            <div :class="`approach-content text_space ${(this.$i18n.locale == 'en-us' ? 'text-left' : '')}`">
              <h3>{{ $t("section_2.item_2.title") }}</h3>
              <p class="mt-3 p_font_size" v-html="$t('section_2.item_2.content')"></p>
              <p class="mt-3 p_font_size" v-html="$t('section_2.item_2.content2')"></p>
            </div>
          </div>

          <div class="col-lg-6 p-0">
            <div class="approach-img">
              <img src="~assets/project_images/pic/esg_02.jpg" alt="Image" />
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-lg-6 px-5">
            <div :class="`approach-content text_space ${(this.$i18n.locale == 'en-us' ? 'text-left' : '')}`">
              <h3>{{ $t("section_2.item_3.title") }}</h3>
              <p class="mt-3 p_font_size" v-html="$t('section_2.item_3.content')"></p>
              <p class="mt-3 p_font_size" v-html="$t('section_2.item_3.content2')"></p>
            </div>
          </div>

          <div class="col-lg-6 p-0">
            <div class="approach-img">
              <img src="~assets/project_images/pic/ESG_03.png" alt="Image" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="my-5">
      <div class="section-title pb-0">
        <h3>{{ $t("section_3.title") }}</h3>
      </div>
      <div class="container" style="max-width: 1000px;">
        <div class="row">
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="approach-img EGS_icon mx-auto rounded-circle">
              <img class="p-5" src="~assets/project_images/pic/EGS_icon_01.svg" />
            </div>
            <div class="pt-3">
              <h4 class="EGS_title text-center pb-3">
                {{ $t("section_3.item_1.title") }}
              </h4>
              <div class="d-flex ">
                <ul class="EGS_content_ul p_font_size" v-html="$t('section_3.item_1.content')"></ul>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 pb-5">
            <div class="approach-img EGS_icon mx-auto rounded-circle">
              <img class="p-5" src="~assets/project_images/pic/EGS_icon_02.svg" width="161" height="161" />
            </div>
            <div class="pt-3">
              <h4 class="EGS_title text-center pb-3">
                {{ $t("section_3.item_2.title") }}
              </h4>
              <div class="d-flex ">
                <ul class="EGS_content_ul p_font_size" v-html="$t('section_3.item_2.content')"></ul>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 pb-5">
            <div class="approach-img EGS_icon mx-auto rounded-circle">
              <img class="p-5" src="~assets/project_images/pic/EGS_icon_03.svg" width="161" height="161" />
            </div>
            <div class="pt-3">
              <h4 class="EGS_title text-center pb-3">
                {{ $t("section_3.item_3.title") }}
              </h4>
              <div class="d-flex ">
                <ul class="EGS_content_ul p_font_size" v-html="$t('section_3.item_3.content')"></ul>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 pb-5">
            <div class="approach-img EGS_icon mx-auto rounded-circle">
              <img class="p-5" src="~assets/project_images/pic/EGS_icon_04.svg" width="161" height="161" />
            </div>
            <div class="pt-3">
              <h4 class="EGS_title text-center pb-3">
                {{ $t("section_3.item_4.title") }}
              </h4>
              <div class="d-flex ">
                <ul class="EGS_content_ul p_font_size" v-html="$t('section_3.item_4.content')"></ul>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 pb-5">
            <div class="approach-img EGS_icon mx-auto rounded-circle">
              <img class="p-5" src="~assets/project_images/pic/EGS_icon_05.svg" width="161" height="161" />
            </div>
            <div class="pt-3">
              <h4 class="EGS_title text-center pb-3">
                {{ $t("section_3.item_5.title") }}
              </h4>
              <div class="d-flex ">
                <ul class="EGS_content_ul p_font_size" v-html="$t('section_3.item_5.content')"></ul>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 pb-5">
            <div class="approach-img EGS_icon mx-auto rounded-circle">
              <img class="p-5" src="~assets/project_images/pic/EGS_icon_06.svg" width="161" height="161" />
            </div>
            <div class="pt-3">
              <h4 class="EGS_title text-center pb-3">
                {{ $t("section_3.item_6.title") }}
              </h4>
              <div class="d-flex ">
                <ul class="EGS_content_ul p_font_size" v-html="$t('section_3.item_6.content')"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "JoinUsContent",
  data() {
    return {};
  },
};
</script>
<!-- <style>
#kec li i {
  margin-right: 10px;
  font-size: 24px;
}
#kec p,
#kec li,
#kec .promise {
  font-size: 16px;
}
#kec .promise span {
  display: contents;
}
</style> -->
<style scoped lang="scss">
#esg {
  img {
    width: 100%;
    height: 100%;
  }

  .container .row {
    .col-lg-12 {
      .section1_item1_title {
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .section1_item1_content {
        margin: 20px 0px 0px 0px;
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .section1_item1_content2 {
        margin: 0px 0px 30px 0px;
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }

    // .col-lg-6 .approach-content {
    //   h3 {
    //     text-align-last: justify;
    //     text-align: justify;
    //   }
    //   p {
    //     font-size: 16px;
    //     text-align: justify;
    //   }
    // }
  }

  .grph-area {
    background-image: url("~assets/project_images/pic/ESG_01.png");
    background-repeat: no-repeat;
    background-size: cover;

    .container .row .col-lg-12 {
      display: flex;
      justify-content: center;
      align-items: center;

      .grph .promise {
        /*height: 380px;*/
        padding: 20px;
        position: relative;
        background-color: rgba(255, 255, 255, 0.5);

        h4 {
          text-align: center;
          margin-bottom: 20px;
          font-weight: 700;
        }
      }
    }
  }
  
}

.EGS_icon {
  width: 161px;
  height: 161px;
  border: solid 1px;
  border-color: #2680ff;
}

.EGS_title {
  color: #2680ff;
  height: 74px;
}

.approach-img {
  margin-bottom: 0px;
}

.approach-content h3 {
  line-height: 30px;
}

.EGS_content_ul :before {
  content: "·";
  font-size: 20px;
  vertical-align: middle;
}


.p_font_size {
  font-size: 16px !important;
}

.section_2_content2 {
  padding-bottom: 10px;
}

.text-left {
  text-align: left !important;
}

.my-5 .row ul {
  text-indent: -21px;
  padding-left: 21px;
}



@media (max-width: 576px) {
  .approach-content p {
  }

  .approach-img img,
  .grph-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .EGS_icon img {
    padding: 2rem !important;
    object-fit: none;
  }
}

@media (max-width: 992px) {
  .text_space {
    padding-bottom: 30px;
  }
}
</style>
<style scoped>
.p_font_size>>>li {

  text-indent: -22px;
  padding-left: 22px;

}
</style>
