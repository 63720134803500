<template>
  <div class="mot-color">
    <Navbar />
    <Header
      :category="'aboutus'"
      :bannerTitle="bannerTitle"
      :breadCrumb="breadCrumb"
    />
    <AboutESGBody />
    <FooterTop />
    <FooterBottom />
  </div>
</template>

<script>
import Navbar from "components/DefaultHome/KECC/Common/NavbarStyle";
import Header from "components/DefaultHome/KECC/Common/Header";
import AboutESGBody from "./AboutESGBody";
import FooterTop from "components/DefaultHome/KECC/Common/FooterTop?v=1.0";
import FooterBottom from "components/DefaultHome/KECC/Common/BacktopButton";

export default {
  name: "AboutESGMain",
  metaInfo() {
    return {
      title: this.$t("navbar.menu_4_1"),
    };
  },
  components: {
    Navbar,
    Header,
    AboutESGBody,
    FooterTop,
    FooterBottom,
  },
  data() {
    return {
      bannerTitle: "MEET<br>OUR TEAM",
      breadCrumb: [
        {
          name: this.$t("navbar.menu_4"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.mot-color {
  background-color: #fff;
}
</style>
